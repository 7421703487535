import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./core/components/PrivateRoute";
import { useAuth } from "./auth/contexts/AuthProvider";

// Admin
const Admin = lazy(() => import("./admin/pages/Admin"));
const Faq = lazy(() => import("./admin/pages/Faq"));
const Home = lazy(() => import("./admin/pages/Home"));
const Profile = lazy(() => import("./admin/pages/Profile"));
const ProfileActivity = lazy(() => import("./admin/pages/ProfileActivity"));
const ProfileInformation = lazy(() => import("./admin/pages/ProfileInformation"));
const ProfilePassword = lazy(() => import("./admin/pages/ProfilePassword"));
const ProfileSignature = lazy(() => import("./admin/pages/ProfileSignature"));

// Auth
const ForgotPassword = lazy(() => import("./auth/pages/ForgotPassword"));
const ForgotPasswordSubmit = lazy(
  () => import("./auth/pages/ForgotPasswordSubmit")
);
const Login = lazy(() => import("./auth/pages/Login"));
const Register = lazy(() => import("./auth/pages/Register"));

// Core
const Forbidden = lazy(() => import("./core/pages/Forbidden"));
const NotFound = lazy(() => import("./core/pages/NotFound"));
const UnderConstructions = lazy(
  () => import("./core/pages/UnderConstructions")
);

// Users
const UserManagement = lazy(() => import("./users/pages/UserManagement"));

// Orders
const OrderManagement = lazy(() => import("./orders/pages/OrderManagement"));
const SingleOrderManagement = lazy(() => import("./orders/pages/SingleOrderManagement"));

// Roles & permissions
const RolesManagement = lazy(() => import("./roles/pages/RolesManagement"));
const PermissionsManagement = lazy(() => import("./permissions/pages/PermissionsManagement"));

// Projects
const ProjectsManagement = lazy(() => import("./projects/pages/ProjectsManagement"));
const SingleProjectManagement = lazy(() => import('./projects/pages/SingleProjectManagement'));


// Workplaces
const WorkplacesManagement = lazy(() => import("./workplaces/pages/WorkplacesManagement"));

// Companies
const CompaniesManagement = lazy(() => import("./companies/pages/CompaniesManagement"));

// Orders Categories 
const OrdersCategoriesManagement = lazy(() => import("./orders_categories/pages/OrdersCategoriesManagement"));

// Currencies
const CurrenciesManagement = lazy(() => import("./currencies/pages/CurrenciesManagement"));

//Calendar
const CalendarApp = lazy(() => import('./calendar/pages/CalendarApp'));

//Vacation
const VacationManagement = lazy(() => import("./vacation/pages/VacationManagement"));

//Pipeline
const PipelineManagement = lazy(() => import('./pipeline/pages/PipelineManagement'));
const SinglePipelineManagement = lazy(() => import('./pipeline/pages/SinglePipelineManagement'));

//Contacts
const ContactsManagement = lazy(() => import('./contacts/pages/ContactsManagement'));

//Resources
const ResourcesManagement = lazy(() => import('./resources/pages/ResourcesManagement'));

//ResourcesHistory
const ResourcesHistory = lazy(() => import('./resources/pages/ResourcesHistory'));

//Leads
const LeadsManagement = lazy(() => import('./leads/pages/LeadsManagement'));

//Processes
const ProcessesManagement = lazy(() => import('./processes/pages/ProcessesManagement'));
const ProcessCategoryManagement = lazy(() => import('./processes/processes_categories/pages/ProcessCategoryManagement'));

//Fleet
const CarsManagement = lazy(() => import('./fleet/cars/pages/CarsManagement'));
const SingleCarManagement = lazy(() => import('./fleet/cars/singleCar/SingleCarManagement'));

const RentalsManagement = lazy(() => import('./fleet/rentals/pages/RentalsManagement'));
const SingleRentalManagement = lazy(() => import('./fleet/rentals/singleRental/pages/SingleRentalManagement'));

//Forecast
const ForecastManagement = lazy(() => import('./forecasts/pages/ForecastManagement'));
const TicketsManagement = lazy(() => import('./fleet/tickets/pages/TicketManagement'));

const WorkersManagement = lazy(() => import('./workers/pages/WorkersManagement'));
const SingleWorkerManagement = lazy(() => import('./workers/singleWorker/pages/SingleWorkerManagement'));

//Reservations
const ConferenceManagement = lazy(() => import('./reservations/conference/pages/ConferenceManagement'));
const TrainingManagement = lazy(() => import('./reservations/training/pages/TrainingManagement'))

//Files
const FilesManagement = lazy(() => import('./files/pages/FilesManagement'));

//Timer 
const TimerUsersManagement = lazy(() => import('./timmer/users/pages/TimerUsersManagement'));
const TimerHoursManagement = lazy(() => import('./timmer/hours/pages/TimerHoursManagement'));

//Property
const PropertyManagement = lazy(() => import('./properties/pages/PropertyManagement'));
const SinglePropertyManagement = lazy(() => import('./properties/singleProperty/pages/SinglePropertyManagement'));

//Report
const ReportManagement = lazy(() => import('./report/pages/reportManagement'));
const AppRoutes = () => {
  const { userInfo } = useAuth();

//Import
const Import = lazy(() => import('./import/pages/Import'));

//Workersimported
const WorkerimportedManagement = lazy(() => import('./workersimported/pages/WorkerimportedManagement'));

  return (
    <Routes basename="/">
      <PrivateRoute path="admin" element={<Admin />}>
        <PrivateRoute path="/" element={<Home />} />
        <PrivateRoute path="faq" element={<Faq />} />
        <PrivateRoute path="help" element={<ReportManagement />} />
        <PrivateRoute path="profile" element={<Profile />}>
          <PrivateRoute path="/" element={<ProfileActivity />} />
          <PrivateRoute path="information" element={<ProfileInformation />} />
          <PrivateRoute path="password" element={<ProfilePassword />} />
          <PrivateRoute path="signature" element={<ProfileSignature />} />
        </PrivateRoute>
        <PrivateRoute path="users" element={<UserManagement />} />
        <PrivateRoute path="orders">
          <PrivateRoute path="/all" key="all" element={<OrderManagement type={["all"]} />} />
          <PrivateRoute path="/add" key="add" element={<OrderManagement type={["add"]} />} />
          <PrivateRoute path="/tocheck" key="tocheck" element={<OrderManagement type={["is_waiting"]} />} />
          <PrivateRoute path="/topay" key="topay" element={<OrderManagement type={["accepted"]} />} />
          <PrivateRoute path="/rejected" key="rejected" element={<OrderManagement type={["rejected"]} />} />
          <PrivateRoute path="/ended" key="rejected" element={<OrderManagement type={["paid_in_cash", "paid_by_bank_transfer"]} />} />
          <PrivateRoute path="/ready" key="rejected" element={<OrderManagement type={["ready_to_pickup"]} />} />
          <PrivateRoute path="/info/:id" key="rejected" element={<SingleOrderManagement />} />
        </PrivateRoute>
        <PrivateRoute path="request">
          <PrivateRoute path="/vacation" element={<VacationManagement/>} />
        </PrivateRoute>
        <PrivateRoute path="roles" element={<RolesManagement />} />
        <PrivateRoute path="permissions" element={<PermissionsManagement />} />
        <PrivateRoute path="projects">
          <PrivateRoute path="/" element={<ProjectsManagement />} />
          <PrivateRoute path="/info/:id" element={<SingleProjectManagement />} />
        </PrivateRoute>
        <PrivateRoute path="workplaces" element={<WorkplacesManagement />} />
        <PrivateRoute path="companies" element={<CompaniesManagement />} />
        <PrivateRoute path="orders-categories" element={<OrdersCategoriesManagement />} />
        <PrivateRoute path="currencies" element={<CurrenciesManagement />} />
        <PrivateRoute path="reservation" element={<CalendarApp />} />
        <PrivateRoute path="pipelines">
          <PrivateRoute path="/" element={<PipelineManagement />} />
          <PrivateRoute path="/info/:id" element={<SinglePipelineManagement />} />
        </PrivateRoute>
        <PrivateRoute path="contacts" element={<ContactsManagement />} />
        <PrivateRoute path="leads" element={<LeadsManagement />} />
        <PrivateRoute path="resources" element={<ResourcesManagement />} />
        <PrivateRoute path="resources-history" element={<ResourcesHistory />} />
        <PrivateRoute path="workers">
          <PrivateRoute path="/" element={<WorkersManagement />} />
          <PrivateRoute path="/info/:id" element={<SingleWorkerManagement />} />
        </PrivateRoute>
        <PrivateRoute path="processes">
          <PrivateRoute path="/" element={<ProcessesManagement />} />
          <PrivateRoute path="/categories" element={<ProcessCategoryManagement />} />
        </PrivateRoute>
        <PrivateRoute path="fleet">
          <PrivateRoute path="/cars" element={<CarsManagement />} />
          <PrivateRoute path="/cars/:id" element={<SingleCarManagement />} />
          <PrivateRoute path="rentals">
            <PrivateRoute path="/" element={<RentalsManagement />} />
            <PrivateRoute path="/info/:id" element={<SingleRentalManagement />} />
          </PrivateRoute>
          <PrivateRoute path="/tickets" element={<TicketsManagement />} />
        </PrivateRoute>
        <PrivateRoute path="forecast" element={<ForecastManagement />} />
        <PrivateRoute path="reservation">
          <PrivateRoute path="/conference" element={<ConferenceManagement />} />
          <PrivateRoute path="/training" element={<TrainingManagement />} />
        </PrivateRoute>
        <PrivateRoute path="timer">
          <PrivateRoute path="/users" element={<TimerUsersManagement />} />
          <PrivateRoute path="/hours" element={<TimerHoursManagement />} />
        </PrivateRoute>
        <PrivateRoute path="files">
          <PrivateRoute path="/:model/:id" element={<FilesManagement />} />
        </PrivateRoute>
        <PrivateRoute path="properties">
          <PrivateRoute path="/" element={<PropertyManagement />} />
          <PrivateRoute path="/info/:id" element={<SinglePropertyManagement />} />
        </PrivateRoute>
        <PrivateRoute path="import">
          <PrivateRoute path="/" element={<Import />} />
        </PrivateRoute>
        <PrivateRoute path="workersimported">
          <PrivateRoute path="/" element={<WorkerimportedManagement />} />
        </PrivateRoute>
      </PrivateRoute>
      <Route path="forgot-password" element={!userInfo ? <ForgotPassword /> : <Navigate to={`/admin`} replace />} />
      <Route path="forgot-password-submit" element={!userInfo ? <ForgotPasswordSubmit /> : <Navigate to={`/admin`} replace />}  />
      <Route path="login" element={!userInfo ? <Login /> : <Navigate to={`/admin`} replace />} />
      <Route path="register" element={!userInfo ? <Register /> : <Navigate to={`/admin`} replace />} />
      <Route path="under-construction" element={!userInfo ? <UnderConstructions /> : <Navigate to={`/admin`} replace />} />
      <Route path="403" element={<Forbidden />} />
      <Route path="404" element={<NotFound />} />
      <Route
        path="/"
        element={!userInfo ? <Navigate to={`/login`} replace /> : <Navigate to={`/admin`} replace />}
      />
      <Route
        path="*"
        element={<Navigate to={`/404`} replace />}
      />
    </Routes>
  );
};

export default AppRoutes;
