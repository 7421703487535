import axios from "axios";
import { useQuery } from "react-query";
import { UserInfo } from "../types/userInfo";


const fetchUserInfo = async (key?: string): Promise<UserInfo> => {
  axios.defaults.headers.common['Authorization'] = "Bearer " + key;
  try {
    const { data } = await axios.get("/user");
    return data;
  } catch (error) {
    if (error instanceof Error) {
        localStorage.removeItem('authkey');
        window.location.href = '/login'
    }
    return new Promise((resolve, reject) => {"Authentication failed..."});
  }
};

export function useUserInfo(key?: string) {
  return useQuery(["user-info", key], () => fetchUserInfo(key), {
    enabled: !!key,
  });
}
