import Box, { BoxProps } from "@material-ui/core/Box";
import { ReactComponent as LogoSvg } from "../assets/logo.svg";
import { useSettings } from "../../core/contexts/SettingsProvider";

type LogoProps = {
  colored?: boolean;
  size?: number;
} & BoxProps;

const LogoLoader = ({ colored = false, size = 100, ...boxProps }: LogoProps) => {
  const { mode } = useSettings();

  return (
    <Box {...boxProps}>
      {mode === "dark" ? (
         <LogoSvg width={size} height="100px" filter="invert(96%) sepia(89%) saturate(0%) hue-rotate(122deg) brightness(110%) contrast(100%)" />
       ) : (
         <LogoSvg width={size} height="100px" />
       )}
    </Box>
  );
};

export default LogoLoader;
